import * as React from 'react';
import { EmptySectionContent } from '../section/EmptySectionContent';
import IconSVG from '../../../../styles/svg-icons';
import { Preloader } from '../../../common';
import { useDownloadDocuments } from '../../../../effects/useDownloadDocuments';
import { DownloadFile } from '../../../../types/amr-pipeline/models/DownloadedDocument';
import { Nullable } from '../../../../types/primitives/Nullable';

interface Props<TArgs extends any[]> {
    title: string;
    itemsCount?: number;
    children: React.ReactNode;
    emptyPlaceholder?: string;
    downloadAllFetch: (...args: TArgs) => Promise<DownloadFile>;
    downloadAllArgs: TArgs;
    fileNameForSave: Nullable<string>;
}

export function DocumentGroup<TArgs extends any[]>({
    title,
    itemsCount = 0,
    children,
    downloadAllFetch,
    downloadAllArgs,
    fileNameForSave,
    emptyPlaceholder,
}: Props<TArgs>) {
    const [collapsed, setCollapsed] = React.useState(false);
    const { handleDownload, isDownloading } = useDownloadDocuments({
        downloadRequest: downloadAllFetch,
        requestArgs: downloadAllArgs,
        fileNameForSave,
    });

    return (
        <div className="document-group-item">
            <div className="document-group-item-title">
                <div className="document-group-click-area" onClick={() => setCollapsed(!collapsed)}>
                    <IconSVG name="corner-arrow" className={`${!collapsed ? 'is-open' : ''}`} width={16} height={16} />
                    <h2>
                        {title}
                        <span className="header-title-count">{itemsCount}</span>
                    </h2>
                </div>
                {!!itemsCount && (
                    <div className="flex-item-right">
                        <Preloader inProgress={isDownloading} small fullScreen={false} text="Downloading...">
                            <a
                                className="btn-link link-download-docs"
                                href="/"
                                onClick={e => {
                                    e.preventDefault();
                                    handleDownload();
                                }}
                            >
                                <IconSVG name="downloadTemplate" width={16} height={16} />
                                <span className="align-middle">Download All</span>
                            </a>
                        </Preloader>
                    </div>
                )}
            </div>
            {!collapsed && (
                <div className="data-item-documents">
                    {itemsCount || !emptyPlaceholder ? children : <EmptySectionContent text={emptyPlaceholder} />}
                </div>
            )}
        </div>
    );
}
