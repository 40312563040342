import { routes } from "../../../constants";
import { SubscriptionFeature } from "../../../types/billing/SubscriptionFeature";
import { ActionBlocker } from "../../access/ActionBlocker";
import { FeatureLink } from "../../access/FeatureLink";

interface Props {
    transactionReferenceName: string;
    dealReferenceName: string;
    children?: React.ReactNode;
    requiredFeatures?: SubscriptionFeature[];
}


export function ImTransactionDetailsLink({
    transactionReferenceName, dealReferenceName, requiredFeatures, children }: Props) {

    return (
        <ActionBlocker features={requiredFeatures}>
            <FeatureLink
                className="btn-link secondary regular"
                path={routes.transactionDetailUrl(transactionReferenceName, dealReferenceName)}
                target="_blank"
            >
                <>{children}</>
            </FeatureLink>
        </ActionBlocker>
    );
}